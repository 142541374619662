import React from 'react'
import Layout from '../components/layout'
import { Link } from 'gatsby'
import byronPic from '../images/people/byron.png'
import missyPic from '../images/people/missy.png'
import maxPic from '../images/people/max.png'


const People = () => (
  <Layout>
    <section className="hero is-primary is-primary">
      <div className="hero-body has-bg-img secondary">
        <div className="container has-text-centered">
          <span className="subtitle">these folks have truly rocked my world</span>
        </div>
      </div>
    </section>
    <section className="section">
      <div className="container people is-fluid">
        <div class="columns">
          <div class="column">
            <h2 class="is-size-4">The Max Wyvern Band</h2>
            <dl>
              <dt>Byron Bellamy</dt>
              <dd><img src={byronPic} className="people" alt="Melissa Olsen" />
                  Vocals (Chief Scientist, First Traveler)</dd>
              <dt>Melissa Olsen</dt>
              <dd><img src={missyPic} className="people" alt="Melissa Olsen" />
                  Keyboards, Vocals (Sky, Second Traveler)</dd>
              <dt>Max Wyvern</dt>
              <dd><img src={maxPic} className="people" alt="Melissa Olsen" />
                  Bass guitar, Vocals (Planet, Third Traveler)</dd>
              <dt>Darryl Dardenne</dt>
              <dd>Drums</dd>
              <dt>Lance Taber</dt>
              <dd>Guitars</dd>
              <dt>Rebecca Rust</dt>
              <dd>Cello (Planet My Love)</dd>
            </dl>

          <p class="is-size-7">
            Photos of the three principle contributors grabbed from <a href="http://jupitersheep.com" target="_blank">The Jupiter Sheep</a>.
            <br/>(they might not be exactly... uh... recent)
          </p>

          </div>
          <div class="column">
            <h2 class="is-size-4">Additional Album Contributors</h2>
            <dl>
              <dt>Joel McKinnon</dt>
              <dd>Librettist and songwriter</dd>
              <dt>Byron Bellamy</dt>
              <dd>Album recording, mixing, studio hosting</dd>
              <dt>Tom Barnes</dt>
              <dd>Album mastering</dd>
              <dt>Sophia Valko</dt>
              <dd>Album cover art</dd>
            </dl>
            <h2 class="is-size-4">Podcast Contributors</h2>
            <dl>
              <dt>Joel McKinnon</dt>
              <dd>Scriptwriter, additional music</dd>
              <dt>Doug Metzger</dt>
              <dd>Narrator, Editor</dd>
              <dt>Melissa Olsen</dt>
              <dd>Ambient music</dd>
              <dt>Alexei Ouzilevski</dt>
              <dd>Additional music</dd>
            </dl>
          </div>
        </div>
        <h2 class="is-size-4">Other contributors or people who inspired the ideas</h2>
          <p>
            Brian Levine, Reed Sandburg, Shauna Pickett-Gordon, Monica Dannenberger, Jeremy MacKinnon, Keren Gaiser, Colin Fischer, Nomi Harper, Friedrich Edelmann,
            John Engle, Julian Barber, Kim Stanley Robinson, Robert Zubrin, Penny Boston, Max Tegmark*,&nbsp;
            <a href="http://marssociety.org" target="_blank">The Mars Society</a>.
          </p>
          <p class="is-size-7">
            *For a reference to a quote by Erwin Schrödinger “…a play before empty benches…”, which spurred the long-delayed completion of the project.
          </p>
          <p class="is-size-7">
            The images used on the song pages were all found on the web many years ago for a different project and I don't know who to thank for them. If any are under copyright
            and the author does not want them used for this purpose, please email planetandsky@gmail.com.
          </p>
      </div>
    </section>
  </Layout>
)

export default People
